<template>
  <button class="btn-primary" @click="click">
    {{title}}
  </button>
</template>

<script>
export default {
  name: 'BlueButton',
  props: ["title"],
  methods: {
    click(){
      this.$emit('click')
    }
  }
}
</script>
<style scoped lang="scss">
.btn-primary {
  background-color: $main-color;
  color: #ffffff;
  text-transform: uppercase;
  padding: 16px 32px;
  outline: none;
  border: 2px solid $main-color;
  border-radius: 8px;
  font-weight: 600;
  transition: all .2s ease-in-out;


  &:hover {
    background-color: #0075BC;
    cursor: pointer;
    border-color: #0075BC;
  }

  &.disabled {
    border-color: rgba(19, 12, 39, 0.16);
    background-color: rgba(19, 12, 39, 0.16);
    color: rgba(19, 12, 39, 0.3);
  }
}
</style>