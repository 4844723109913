<template>
  <iframe id="my-iframe" :src="iframeUrl" @load="resizeIframe"></iframe>
</template>

<script>
export default {
  data() {
    return {
      iframeUrl: '/Refugee_Employability_Programme_-_South-West_England_Referral_Form__Self-Referral/Refugee_Employability_Programme_-_South-West_England_Referral_Form__Self-Referral_.html'
    };
  },
  methods: {
    resizeIframe() {
      const iframe = document.getElementById('my-iframe');
      if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
        const height = iframe.contentWindow.document.documentElement.scrollHeight;
        iframe.style.height = height + 'px';
      }
    }
  }
}
</script>

<style scoped>
#my-iframe {
  width: 100%;
  height: 100%;
  border: 0;
  overflow-y: hidden;
}
</style>