<template>
  <div class="container-contact">
    <div class="banner" :style="getBanner()">
      <div class="container">
        <div class="banner-content">
          <h1 class="text-default">
            {{ $t('contactUs') }}
          </h1>
        </div>
      </div>
      <img alt="" class="corner" src="@/assets/icons/slice-top.png">
    </div>
    <div class="container content">
      <div class="box-title">
        <p class="title">
          {{ $t('anyQuestions') }}
          <span>
          {{ $t('youCanAlsoContactUsToGetSupportWithYourApplication') }}
        </span>
        </p>
        <div class="box-contacts">
          <div :class="{scale: selectedLanguage !== 'en'}" class="item-email">

            <div class="value-box">
              <div class="name">
                <img alt="" src="@/assets/icons/icon-email.svg">
                <p :class="{scale: selectedLanguage !== 'en'}">{{ $t('email2') }}</p>
              </div>
              <div class="value">
                <p ref="email" :class="{scale: selectedLanguage !== 'en'}">rep.sw@rescue.org</p>
                <img alt="" src="@/assets/icons/content_copy.svg" @click="copyEmail">
                <Transition>
                  <div v-if="showCopyNotification" class="copy-notification">
                    {{ $t('emailWasCopied') }}
                  </div>
                </Transition>

              </div>
            </div>
            <img alt="" class="corner" src="@/assets/images/purple-corner.png">
          </div>
          <div :class="{scale: selectedLanguage !== 'en'}" class="item-phone">
            <div class="value-box">
              <div class="name">
                <img alt="" src="@/assets/icons/icon-call.svg">
                <p :class="{scale: selectedLanguage !== 'en'}">
                  {{ $t('freeTelephone') }}
                </p>
              </div>

              <a :class="{scale: selectedLanguage !== 'en'}" class="value"
                 href="tel:+08082940124">
                0808 294 0124
              </a>

            </div>
            <img alt="" class="corner" src="@/assets/images/green-corner.png">
          </div>
        </div>

        <div class="accordion-group">
          <Accordion :contact="true" is-blue-arrow="true">
            <template v-slot:title>
              <h4 class="contact-accordion">{{ $t('giveUsYourFeedback') }}</h4>
            </template>
            <template v-slot:content>
              <div>
                <p>
                  {{ $t('weAreCommittedToProvidingHighqualityServicesToAllP') }}<br>

                  <br>{{ $t('youHaveTheRightToPresentYourComplimentsConcernsSug') }}<br>

                  <br>{{ $t('youCanProvideFeedbackOrFileAComplaintAnonymouslyWi') }}<br>

                  <br>{{ $t('youWillBeTreatedFairlyAndGivenPromptUnbiasedConsid') }}<br>

                  <br>{{ $t('howToMakeAComplaintOfferFeedbackOrSuggestion') }}<br>
                </p>


                <ul :class="[{'right-side-markers': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                  <li>
                    {{ $t('facetoFaceYouCanDiscussDirectlyWithYourCaseWorkerO') }}
                  </li>
                  <li>
                    {{ $t('PhoneYoucancallourfreephonenumber08082940124andchooseoption4togive') }}
                    <a dir="rtl" href="tel:+08082940124">24 401 829 080</a>
                    {{ $t('togivefeedbackandfilecomplaints') }}
                  </li>
                  <li>
                    <p :class="[{'direction': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                      <span v-if="selectedLanguage !== 'en'">
                        {{ $t('orSubmitAComplaintToTheAddressIntegrityrescueorgYo') }}
                      </span>
                      <span v-if="selectedLanguage === 'en'">
                        {{ $t('writtenComplaintAWrittenComplaint') }}
                         <a :href="'https://rescue.jotform.com/232533638091052'">{{ $t('rescueJotform') }}</a>
                        {{ $t('orEmailedToThe') }}
                      </span>
                      <span>
                      <a :href="'mailto:' + $t('mailrep.sw@rescue.org')">{{ $t('mailrep.sw@rescue.org') }}</a>
                    </span>
                      {{ $t('Aftermailcontact') }}
                      {{ $t('ukSafeguardingAdvisor') }}
                      {{ $t('Aftermailcontact2') }}
                      <span>
                      <a :href="'mailto:' + $t('mailsafe.uk@rescue-uk.org')">{{ $t('safe.uk@rescue-uk.org') }}</a>
                    </span>
                      {{ $t('Aftermailcontact4') }}
                    </p>
                  </li>
                  <li>
                    <p>
                      {{ $t('Aftermailcontact3') }}<br>
                      {{ $t('Adresaftermailcontact') }}
                    </p>
                  </li>
                </ul>
              </div>

            </template>
          </Accordion>

          <accordion :contact="true"
                     is-blue-arrow="true"
                     v-for="(item, indexFaq) in faqList"
                     :key="indexFaq">
            <template v-slot:title>
              <h4 class="contact-accordion">{{item.question}}</h4>
            </template>
            <template v-slot:content>
              <p>{{item.answer}}</p>
            </template>
          </accordion>
        </div>


        <div id="subscribeBlock">
          <subscribe-block/>
        </div>

        <div id="donationBlock">
          <donations-block/>
        </div>

        <div id="scrollBlock">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/Accordion.vue";
import axios from "axios";
import subscribeBlock from "@/components/subscribeBlock.vue";
import DonationsBlock from "@/components/DonationsBlock.vue";
import {query} from "vue-gtag";

export default {
  name: 'ContactPage',
  components: {DonationsBlock, Accordion, subscribeBlock},
  props: ['selectedLanguage'],
  data() {
    return {
      showCopyNotification: false,
      banner: {},
      faqList: [],
    }
  },

  watch: {
    '$route'(to) {
      if (to.hash && to.query.scroll === 'true') {
        this.scrollToSubscribeBlock();
      }
    }
  },

  async mounted() {
    await this.initBanner();
    await this.fetchFaq();
    if (this.$route.query.scroll === 'true') {
      this.scrollToSubscribeBlock();
    }
  },

  methods: {
    scrollToSubscribeBlock() {
      const element = document.querySelector('#subscribeBlock');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth'});
        setTimeout(this.clearScrollQuery, 2000);
      }
    },
    clearScrollQuery() {
      const { hash, path, query } = this.$route;
      if (query.scroll) {
        const newQuery = { ...query };
        delete newQuery.scroll;
        const newUrl = this.$router.resolve({ path, query: newQuery, hash }).href;
        window.history.replaceState(null, '', newUrl);
      }
    },
    copyEmail() {
      const storage = document.createElement('textarea');
      storage.value = this.$refs.email.innerHTML;
      this.$refs.email.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.email.removeChild(storage);
      this.showCopyNotification = true;
      setTimeout(() => {
        this.showCopyNotification = false;
      }, 1000)
    },
    getBanner() {
      if (this.banner.url) {
        return {
          backgroundImage: 'url(' +process.env.VUE_APP_API_URL+ this.banner.url + ')'
        }
      }
      else {
        return {}
      }
    },
    async initBanner(){
      await axios.get(process.env.VUE_APP_API_URL + "/images/contact").then((response) => {
        this.banner = response.data;
      });
    },
    async fetchFaq() {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/faq');
        this.faqList = response.data.filter(faq => faq.main === true);
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    }

  },
}
</script>

<style lang="scss" scoped>

.accordion-group{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.direction {
  direction: rtl;
}

ul {
  padding-left: 20px;
}


ul.right-side-markers {
  list-style: none; /* Убираем стандартные маркеры */
  padding: 0;
}

ul.right-side-markers li {
  position: relative; /* Для позиционирования псевдоэлемента */
  padding-right: 20px; /* Отступ справа для маркера */
}

ul.right-side-markers li::before {
  content: '•'; /* Символ маркера */
  position: absolute;
  right: 0; /* Позиционирование справа */
  top: 0;
}

a {
  color: blue; /* Устанавливаем цвет ссылки */
}

a:visited {
  color: blue; /* Цвет посещённой ссылки */
}

a:hover {
  color: blue; /* Цвет ссылки при наведении */
}

a:active {
  color: blue; /* Цвет активной ссылки */
}


.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.scale {
  transform: scale(-1, 1)
}

.contact-accordion {
  width: 100%;
}

.content {
  min-height: 50vh;
}

.container-contact {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .banner {
    height: 482px;
    //background-image: url("@/assets/images/banner-contact.png");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

    .corner {
      width: 128px;
      position: absolute;
      top: -2px;
      @media (max-width: 1224px) {
        width: 70px;
        right: 0;
        transform: rotate(90deg);
      }

    }

    @media (max-width: 1224px) {
      background: rgba(146, 197, 235, 1);
      height: 200px;
      background-image: none!important;

      .text-default {
        font-size: calc(var(--main-font-size) + 8px) !important;
      }
    }


    .text-default {
      font-size: calc(var(--main-font-size) + 32px);
      color: #034694;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 1.44px;
      width: 35%;
      @media (max-width: 1400px) {
        width: 30%;
      }
      @media (max-width: 1224px) {
        width: 100%;
      }

      span {
        font-weight: 400;
      }
    }
  }

  .box-title {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 7.5rem;

    .title {
      color: #024694;
      font-size: calc(var(--main-font-size) + 8px);
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 0.72px;
      display: flex;
      flex-direction: column;

      @media (max-width: 1224px) {
        font-size: calc(var(--main-font-size) + 4px);
      }

      span {
        margin-top: 16px;
        color: #262532;
        font-size: calc(var(--main-font-size) + 2px);
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .box-contacts {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .item-email {
      border-radius: 8px 0 0 8px;
      background: #A9DBD2;
      position: relative;
      padding: 32px;
      display: flex;
      gap: 1rem;

      .value-box {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .name {
          color: #000;
          font-size: calc(var(--main-font-size) + 8px);
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          height: fit-content;
          margin: auto 0;
          display: flex;
          gap: 0.5rem;

          p {
            height: fit-content;
            margin: auto;
          }
        }

        .value {
          @extend .name;
          color: #184892;
          position: relative;
          text-decoration: none;

          img {
            cursor: pointer;
          }

          .copy-notification {
            font-size: calc(var(--main-font-size) - 6px);
            position: absolute;
            right: -40px;
            top: 37px;
            background: $main-color;
            padding: 0 8px;
            height: 24px;
            display: flex;
            align-items: center;
            color: white;
            transition: opacity 0.5s ease;
          }
        }
      }
    }


    .corner {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;

    }
  }

  .item-phone {
    @extend .item-email;

    .corner {
      transform: rotate(270deg);
    }
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    padding: 0 1rem;
  }

}

@media only screen and (max-width: 1224px) {
  .container-contact {
    .contact-banner {
      background-image: none;
      background-color: #92c5eb;
      height: 200px;

      .text-default {
        font-size: calc(var(--main-font-size) + 8px);
      }
    }

    .box-title {
      .title {
        font-size: calc(var(--main-font-size) + 4px);

      }
    }

    .box-contacts {
      .item-email {
        border-radius: 8px;

        .value-box {
          gap: 0.5rem;
        }

        .corner {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container-contact {
    .box-contacts {
      .item-email {
        border-radius: 8px;

        .value-box {
          gap: 0.5rem;

          .name {
            font-size: calc(var(--main-font-size) + 4px);
          }
        }

      }
    }
  }
}


</style>