<template>
  <div class="container-events">

    <div class="events-wrapper">
      <div class="container-event">

        <span class="container-description">
          {{ $t('willGladToSee') }} <br>
          {{ $t('saveTheDate') }}
        </span>

        <div class="selection-date">
          <radio-btn :options="eventOptions" @change="updateSelectedMonthLabel"/>
        </div>

        <span class="date-description">
          {{ monthNames[selectedDate.getMonth()] + " " + selectedDate.getFullYear() }} Morning and Evening Sessions
        </span>

      </div>

      <div v-for="(event, indexEvent) in filteredEvents" :key="indexEvent" class="item">
        <div class="title-group">
          <span class="title">{{ event.title }} <span class="type">{{ event.type }}</span></span>
          <div v-if="(!isMobile && (event.url !== ''))" @click="openEvent(event.url)" class="btn">
            Sign Up
          </div>
        </div>
        <p class="description" v-html="event.description"></p>
        <div class="session-group">
          <div v-if="!event.approximate" class="group">
            <p class="session">Starts</p>
            <p class="date">{{ formatFullDate(event.dates[event.selectedDate].date) }}</p>
          </div>
          <div v-if="!event.approximate" class="all-date-group">
            <div
                v-for="(dateObj, dateIndex) in event.dates"
                :key="dateIndex"
                :class="['date', { selected: event.selectedDate === dateIndex }]"
                @click="selectDate(event, dateIndex)"
            >
              <p>{{ dateFormatter(dateObj.date) }}</p>
            </div>
          </div>
          <div v-else class="all-date-group">
            <div v-if="event.dates" class="date">
              <p>{{ dateApproximateFormatter(event.dates[0].date) }}</p>
            </div>
          </div>
        </div>
        <div class="leaflet-group">
          <div v-for="(leaf, indexLeaflet) in event.leaflet" :key="indexLeaflet" class="item-leaflet">
            <img :alt="$t('document')" class="document" src="@/assets/icons/document.svg">
            <div class="text">
              <p>{{ leaf.title }}</p>
            </div>
            <img @click="showPoster(leaf.url)" :alt="$t('download')" class="download" src="@/assets/icons/download.svg">
          </div>
        </div>

        <div v-if="(isMobile && (event.url !== ''))" @click="openEvent(event.url)" class="btn">
          Sign Up
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import RadioBtn from "@/components/shared/radioBtn.vue";
import BtnMain from "@/components/shared/btnMain.vue";

export default {
  name: "Events",
  components: {RadioBtn, BtnMain},
  props: ['events'],

  data() {
    return {
      selectedDate: new Date(),
      eventOptions: this.generateEventOptions(),
      filteredEvents: [],
      monthNames: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      isMobile: false
    }
  },
  mounted() {
    console.log(this.events)
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
    this.filteredEvents = this.filterEvents(this.events);
  },
  watch: {
    events: {
      handler(newEvents) {
        newEvents.forEach(event => {
          if (event.dates && event.dates.length > 0 && typeof event.selectedDate === 'undefined') {
            this.$set(event, 'selectedDate', 0);
          }
        });
        this.filteredEvents = this.filterEvents(newEvents);
      },
      immediate: true
    },
    selectedDate() {
      this.filteredEvents = this.filterEvents(this.events);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIsMobile);
  },
  methods: {
    dateFormatter(dateString) {
      let date = new Date(dateString)
      return date.toLocaleDateString('en-GB', {day: '2-digit', month: 'short'})
    },
    dateApproximateFormatter(dateString) {
      let date = new Date(dateString)
      return date.toLocaleDateString('en-GB', {month: 'long'})
    },
    formatFullDate(dateString) {
      let date = new Date(dateString);
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
    },

    generateEventOptions() {
      const currentDate = new Date();
      const options = [];
      for (let i = 0; i < 4; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i);
        options.push(date);
      }
      return options;
    },
    updateSelectedMonthLabel(selectedOption) {
      this.selectedDate = selectedOption;
      this.filteredEvents = this.filterEvents(this.events);
    },

    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },

    openEvent(url) {
      window.open(url, '_blank');
    },
    selectDate(event, dateIndex) {
      event.selectedDate = dateIndex
    },

    filterEvents(events) {
      if (!events) {
        return [];
      }
      if (!this.selectedDate) {
        return events;
      }
      const selectedMonth = this.selectedDate.getMonth();

      const filteredEvents = events.filter(event => {
        if (!event.dates) {
          return false;
        }

        const hasMatchingDate = event.dates.some(dateObj => {
          const eventDate = new Date(dateObj.date);
          return eventDate.getMonth() === selectedMonth;
        });

        return hasMatchingDate;
      });

      return filteredEvents;
    },
    showPoster(url) {
      window.open(process.env.VUE_APP_API_URL + url, '_blank').focus();
    },
  },
}
</script>

<style lang="scss" scoped>
.container-events {
  display: flex;
  flex-direction: column;
  text-align: start !important;
  background: red;

  .banner {
    height: 482px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

    .corner {
      width: 128px;
      position: absolute;
      top: -2px;
      @media (max-width: 1224px) {
        width: 70px;
        right: 0;
        transform: rotate(90deg);
      }
    }

    @media (max-width: 1224px) {
      background: #dee1da;
      height: 200px;

      .text-default {
        font-size: calc(var(--main-font-size) + 8px) !important;
      }
    }

    .text-default {
      font-size: calc(var(--main-font-size) + 32px);
      color: #034694;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 1.44px;
      direction: ltr;

      span {
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    padding: 0 1rem;
  }
}

.events-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 2rem auto;
  width: 60%;
  border-radius: 16px;
  background: #F0F7FC;
  padding: 2rem;
}

.accordion-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.badge-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F0F7FC;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 1rem;

}

.badge-header h4 {
  margin: 0;
  font-size: 24px;
  color: #000000;
}

.badge-header h4.opened {
  color: #024694;
}

.badge-header p {
  margin: 0;
  font-size: 16px;
  color: #6A88B8;
}

.header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-icon.rotate {
  transform: rotate(90deg);
}


.item {
  padding: 1rem;
  //border-radius: 8px;
  background: #FFF;
  width: 100%;
}

.line {
  width: 100%;
  position: relative;
  border: 1px solid #02469429;
  box-sizing: border-box;
  height: 1px;
  margin: 32px 0;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: none;
  border-radius: 8px;
  background: #184892;
  height: 32px;
  min-width: 125px;
  border: none;
  outline: none;
  padding: 0.5rem 2rem;
  color: #FFF;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.096px;
  cursor: pointer;
}

.date {
  cursor: pointer;
}

.container-event {
  padding: 2rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .container-title {
    color: #024694;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.96px;
  }

  .container-description {
    color: #034694;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    letter-spacing: 0.72px;
  }

  .selection-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .date-title {
      color: #000;
      text-align: center;
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */

      &.selected {
        color: #184892;
      }
    }
  }
}

.date-description {
  color: #000;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
}

.container-events {
  border-radius: 8px;
  background: #FFF;

  .btn {
    margin-right: auto;
    cursor: pointer;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 4rem;
    position: relative;

    .title-group {
      display: flex;
      justify-content: space-between;

      .title {
        color: #000;
        font-family: 'Arial', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin: 0;
      }

      .type {
        color: #6A88B8;
        font-family: Arial, sans serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 27px */
      }

      .btn {
        margin-right: 0;
        cursor: pointer;
      }
    }

    .description {
      color: #000;
      font-family: 'Arial', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin: 0;
      ::v-deep ul{
        margin: 0 0 0 20px;
      }
    }

    .session-group {
      display: flex;
      justify-content: space-between;

      .group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        min-width: 10rem;


        .session {
          color: #6A88B8;
          font-family: 'Arial', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          margin: 0;
        }

        .date {
          @extend .session;
          color: #000;
          font-weight: 400;
          cursor: pointer;

          &.selected {
            background-color: #184892;
            color: #FFF;
          }
        }
      }

      .all-date-group {
        display: flex;
        gap: 1rem;
        margin-left: auto;
        flex-wrap: wrap;


        .date {
          border-radius: 8px;
          background: #DFF1FF;
          padding: 6px 10px;
          height: fit-content;
          margin: auto 0;

          p {
            color: #000;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin: 0;
          }
        }
      }
    }

    .leaflet-group {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .item-leaflet {
        display: flex;
        gap: 1rem;

        .document {
          width: 2rem;
          height: 2rem;
          margin: auto 0;
        }

        .download {
          width: 1.5rem;
          height: 1.5rem;
          margin: auto 0;
          cursor: pointer;
        }

        .text {
          color: #000;
          font-family: 'Arial', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          margin: auto 0;
        }
      }
    }
  }

  .item::before {
    content: '';
    position: absolute;
    bottom: 2rem;
    left: 0;
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, rgba(2, 70, 148, 0.00) 0%, rgba(2, 70, 148, 0.16) 42.5%, rgba(2, 70, 148, 0.00) 100%);
  }


  .item:last-child::before {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .container-events {

    .banner {
      height: 200px;
      text-align: center;

      .corner {
        width: 50px;
        right: 0;
        transform: rotate(90deg);
      }

      .text-default {
        font-size: 1.5rem;
      }
    }

    .events-wrapper {
      width: 100%;
      margin: 0;
    }

    .container-event {
      padding: 1rem;
      text-align: center;

      .container-title {
        font-size: 1.5rem;
      }

      .container-description {
        font-size: 1rem;
      }

      .selection-date {
        gap: 1rem;
      }

      .date-description {
        font-size: 18px;
      }
    }

    .accordion-container {
      padding: 0 1rem;

      .badge-header {
        padding: 0.5rem 1rem;
        margin-bottom: 3rem;

        h4 {
          font-size: 1rem;
        }

        p {
          font-size: 0.875rem;
        }

      }

      .item {
        padding: 0.5rem;

        .title-group {
          flex-direction: column;
          gap: 1rem;

          .title {
            font-size: 1rem;
            text-align: center;
          }

          .btn {
            padding: 0.25rem 1rem;
            font-size: 0.875rem;
          }
        }

        .description {
          font-size: 0.875rem;
          text-align: center;

        }

        .session-group {
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;

          .group {
            align-items: flex-start;


          }

          .all-date-group {
            flex-wrap: wrap;
            gap: 0.5rem;

            .date {
              font-size: 0.75rem;
            }
          }
        }

        .leaflet-group {
          flex-direction: column;
          align-items: center;

          .item-leaflet {

            .document {
              width: 1rem;
              height: 1rem;
            }

            .text {
              font-size: 0.875rem;
            }
          }
        }

        .btn {
          width: 100%;
        }
      }
    }
  }
}

</style>
