<template>
  <div :class="{'contact': contact, 'events': events}" class="accordion">
    <div :class="{'title': contact, 'title-events': events, 'active': show && events}" class="header" @click="toggle" id="ac-header">
      <slot name="title" :selectedLanguage="selectedLanguage"></slot>
      <img v-if="!isBlueArrow" :class="{ 'rotate': show }" :alt="$t('arrow')" class="header-icon"
           src="@/assets/icons/arrow_forward.svg">
      <img v-else :class="{'rotate': show}" :alt="$t('arrow')" class="header-icon" src="@/assets/icons/blue-arrow.svg">
    </div>
    <transition :class="{'contact': contact, 'events': events}" name="accordion"
                v-on:enter="enter" v-on:leave="leave"
                v-on:before-enter="beforeEnter" v-on:before-leave="beforeLeave">
      <div v-show="show" :class="{'border-events': events}" class="body">
        <div :class="{'filling': contact, 'filling-events': events}" class="body-inner">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>



<script>
import Events from "@/views/Events.vue";

export default {
  name: 'Accordion',
  components: {Events},
  props: ['contact', 'isBlueArrow', 'opened', 'selectedLanguage', 'events'],
  data() {
    return {
      show: false,
      isRoundedCorners: true
    }
  },
  mounted() {
    document.addEventListener('click', this.closeAccordionOnOutsideClick);
    if (this.opened) {
      this.show = true;
    }
  },

  beforeDestroy() {
    document.removeEventListener('click', this.closeAccordionOnOutsideClick);
  },
  methods: {
    toggle() {
      this.show = !this.show;
      if (this.show) {
        this.$emit('opened');
      }
    },
    beforeEnter(el) {
      el.style.height = '0';
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px';

      setTimeout(() => {
        if (Number(el.style.height.replace('px', '') !== el.scrollHeight)) {
          el.style.height = el.scrollHeight + 'px';
        }
      }, 200)
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave(el) {
      el.style.height = '0';
    },
    closeAccordionOnOutsideClick(event) {
      if (!this.$el.contains(event.target) && (event.target?.id === 'ac-header' || event.target?.parentElement?.id === 'ac-header')) {
        this.show = false;
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.accordion {
  min-width: 400px;
  background-color: rgb(104, 100, 177);
  border-radius: 8px;

  @media (max-width: 1224px) {
    min-width: 100%;
  }
}

//.article {
//  background: #F0F7FC;
//  .title-article {
//    color: #000;
//    font-family: 'Poppins', sans-serif;
//    font-size: 24px;
//    font-style: normal;
//    font-weight: 600;
//    line-height: 124%;
//  }
//
//  .body {
//    border: 10px solid #F0F7FC !important;
//  }
//}

.contact {
  background-color: #DEE1DA;

  .title {
    color: #024694 !important;
    font-size: calc(var(--main-font-size) + 8px);
    font-style: normal;
    font-weight: 700;
    line-height: 124% !important;
    height: fit-content !important;

    .arrow {
      filter: brightness(0) invert(0);
    }
  }

  .filling {
    color: #262532 !important;
    font-size: calc(var(--main-font-size) + 2px);
    font-style: normal;
    font-weight: 400;
    line-height: 150% !important;
  }

  //.border {
  //  border: 10px solid #DEE1DA !important;
  //}
}
.filling.body-inner{
  padding: 0 3rem 1.5rem 1.5rem!important;
}
.title.header{
  padding: 1.5rem 3rem 1.5rem 1.5rem !important;
}

.events {
  background-color: #F0F7FC;

  .title-events {
    color: #000; // Черный цвет по умолчанию
    font-size: calc(var(--main-font-size) + 8px);
    font-style: normal;
    font-weight: 700;
    line-height: 124% !important;

    &.active {
      color: #024694 !important; // Цвет при открытии
    }
  }

  .filling-events {
    color: #262532 !important;
    font-size: calc(var(--main-font-size) + 2px);
    font-style: normal;
    font-weight: 400;
    line-height: 150% !important;
  }

  .border-events {
    border: 1px solid transparent !important; // Прозрачные границы
  }
}

.accordion .header {
  min-height: 64px;
  line-height: 40px;
  padding: 16px 48px 16px 24px;
  position: relative;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.events {
    color: #000000;
  }
}

.accordion .header-icon {
  position: absolute;
  right: 16px;
  transform: rotate(0deg);
  transition-duration: 0.3s;
}

.accordion .body {
  overflow: hidden;
  //border: 10px solid rgb(104, 100, 177);
  border-top: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: 150ms ease-out;
}

.accordion .body-inner {
  padding: 0 24px 16px 24px;
  overflow-wrap: break-word;
  color: white;
}

.accordion .header-icon.rotate {
  transform: rotate(-90deg);
  transition-duration: 0.3s;
}
</style>
