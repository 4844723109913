<template>
  <div class="container-refer-someone">
    <div class="banner" :style="getBanner()">
      <div class="container">
        <div class="banner-content">
          <h1 class="text-default">
            <span>{{ $t('referPeopleToThe') }}</span><br>
            {{ $t('refugeeEmployability') }} <br>
            {{ $t('programme') }}
          </h1>
        </div>
      </div>
      <img alt="" class="corner" src="@/assets/icons/slice-top.png">
    </div>
    <FormBlock/>
  </div>

</template>

<script>
import FormBlock from "@/components/ReferFormBlock.vue";
import axios from "axios";

export default {
  name: 'ReferSomeonePage',
  components: {FormBlock},
  data() {
    return {
      banner: {},
    }
  },
  methods: {
    getBanner() {
      if (this.banner.url) {
        return {
          backgroundImage: 'url(' + process.env.VUE_APP_API_URL + this.banner.url + ')'
        }
      } else {
        return {}
      }
    },
    async initBanner() {
      await axios.get(process.env.VUE_APP_API_URL + "/images/refer-someone").then((response) => {
        this.banner = response.data;
      });
    },
  },
  async mounted() {
    await this.initBanner();
  }
}
</script>

<style lang="scss" scoped>
.container-refer-someone {
  display: flex;
  flex-direction: column;
  text-align: start !important;

  .banner {
    height: 482px;
    //background-image: url("@/assets/images/banner-refer-someone.png");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

    .corner {
      width: 128px;
      position: absolute;
      top: -2px;
      @media (max-width: 1224px) {
        width: 70px;
        right: 0;
        transform: rotate(90deg);
      }

    }

    @media (max-width: 1224px) {
      background: #dee1da;
      height: 200px;
      background-image: none!important;

      .text-default {
        font-size: calc(var(--main-font-size) + 8px) !important;
      }
    }


    .text-default {
      font-size: calc(var(--main-font-size) + 32px);
      color: #034694;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 1.44px;
      direction: ltr;

      span {
        font-weight: 400;
      }
    }
  }
}

.form-container {
  min-height: 100vh;
  padding-top: 143px;
}

@media only screen and (max-width: 1300px) {
  .container {
    padding: 0 1rem;
  }


}

@media only screen and (max-width: 1225px) {
  .form-container {
    padding-top: 1.5rem;
  }
}


</style>