<template>
  <div class="custom-select" :class="{ open: isDropdownOpen }">
    <div class="select-header" @click="toggleDropdown">
      {{ selectedOptionText }}
      <img :alt="$t('down')" class="arrow-down" :class="{ coup: isDropdownOpen }" src="@/assets/icons/arrow_down.svg">
    </div>
    <ul v-if="isDropdownOpen" class="options">
      <li v-for="option in options" :key="option.value" @click="selectOption(option.value)">{{ option.text }}</li>
    </ul>
    <div class="select-overlay" v-if="isDropdownOpen" @click="closeOptions"></div>
  </div>

</template>

<script>
export default {
  name: 'SelectComponent',
  props: ["options"],
  data() {
    return {
      selectedOption: this.options.length > 0 ? this.options[1].value : null,
      isDropdownOpen: false,
    };
  },
  computed: {
    selectedOptionText() {
      const selectedOption = this.options.find(option => option.value === this.selectedOption);
      return selectedOption ? selectedOption.text : "";
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(value) {
      this.selectedOption = value;
      this.$emit('categoryChanged', value);
      this.isDropdownOpen = false;
    },
    closeOptions() {
      this.isDropdownOpen = false;
    }
  },
}
</script>

<style scoped lang="scss">
.custom-select {
  position: relative;
  width: 100%;
}

.select-header {
  cursor: pointer;
  padding: 7px 35px 7px 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  font-size: var(--main-font-size);
  line-height: 1.5rem;
}

.options {
  list-style: none;
  margin: 0;
  position: absolute;
  top: 100%;
  border-radius: 8px;
  left: 0;
  transform: translate(0, 2%);
  border: 1px solid #ccc;
  display: none;
  background: #fff;
  width: 100%;
  padding: 0.25rem 0;
  z-index: 9;
}

.options li {
  cursor: pointer;
  padding: 5px 12px;
}
.options li:hover {
  background: rgba(133, 133, 133, 0.2);
}

.custom-select.open .options {
  display: block;
}
.arrow-down{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-80%, -50%) rotate(0deg);
  transition: transform .3s ease-in-out;
}
.coup{
  transform: translate(-80%, -50%) rotate(180deg);
}
.select-overlay {
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
}
</style>