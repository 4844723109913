<template>
<div class="container">
  <div class="text-container">
    <span class="title">{{ $t('makeADonation') }}</span>
    <span class="sub-title">{{ $t('theInternationalRescueCommittee') }}</span>
  </div>

  <a class="button" id="DonateInBlock" href="https://help.rescue-uk.org/donate-evergreen?ms=ws_resq_top_nav_btn_fy24_gen_unres_ukmk_may&initialms=ws_resq_top_nav_btn_fy24_gen_unres_ukmk_may" target="_blank">
    <span class="button-text">{{ $t('donate') }}</span>
  </a>

</div>
</template>

<script>
export default {
  name: "DonationsBlock"
}
</script>

<style scoped lang="scss">

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  margin-top: 88px !important;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 808px;
  width: 100%;
}

.title {
  color: #184892;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 59.52px */
  letter-spacing: 1.44px;
}

.sub-title {
  color: #262532;
  text-align: center;
  -webkit-text-stroke-color: #161616;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.button {
  display: flex;
  max-width: 432px;
  width: 100%;
  padding: 8px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #184892;
  border-radius: 8px;
  text-decoration: none;

  &:hover {
    background-color: #466BBF;
  }
}

.button-text {
  color: var(--black-white-white, #FFF);
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.096px;
  text-decoration: none;
}

</style>