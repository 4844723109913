<template>
  <div class="containerSub">
    <div class="subscribe-block">
      <h1>{{ $t('subscribeForSomething') }}</h1>
      <p>{{ $t('hereIsHowTheRefugeeEmployabilityProgrammeWorksAndWhat') }}</p>
      <img :alt="$t('subscribe')" src="@/assets/images/subscribe.png">
    </div>

    <div v-if="!isSubscribed" class="subscription-form">
      <span class="title">Subscribing</span>
      <div>
        <span class="subtitle">You are</span>
        <div class="radio-group">
          <label>
            <input type="radio" name="userType" value="refugee" v-model="selected">
            <svg v-if="selected === 'refugee'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                 viewBox="0 0 24 24" fill="none">
              <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#034694"/>
              <rect x="4" y="4" width="16" height="16" rx="8" fill="#034694"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#034694"/>
            </svg>
            <span class="subtitle variables">Refugee</span>
          </label>
          <label>
            <input type="radio" name="userType" value="organisation" v-model="selected">
            <svg v-if="selected === 'organisation'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                 viewBox="0 0 24 24" fill="none">
              <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#034694"/>
              <rect x="4" y="4" width="16" height="16" rx="8" fill="#034694"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#034694"/>
            </svg>
            <span class="subtitle variables">Organisation</span>
          </label>
        </div>
      </div>

      <div style="width: 100%">
        <form @submit.prevent="subscribe">
          <div class="form-group">
            <label for="firstName">
              <span class="subtitle"><span class="required">*</span> First name</span>
            </label>
            <input
                type="text"
                id="firstName"
                v-model="firstName"
                @input="validateFirstName"
                @blur="validateFirstName"
                placeholder="Input"
            />
            <span class="error" v-if="errors.firstName">{{ errors.firstName }}</span>
          </div>
          <div class="form-group">
            <label for="lastName">
              <span class="subtitle"><span class="required">*</span> Last name</span>
            </label>
            <input
                type="text"
                id="lastName"
                v-model="lastName"
                @input="validateLastName"
                @blur="validateLastName"
                placeholder="Input"
            />
            <span class="error" v-if="errors.lastName">{{ errors.lastName }}</span>
          </div>
          <div class="form-group">
            <label for="email">
              <span class="subtitle"><span class="required">*</span> Email</span>
            </label>
            <input
                type="email"
                id="email"
                v-model="email"
                @input="validateEmail"
                @blur="validateEmail"
                placeholder="E-mail"
            />
            <span class="error" v-if="errors.email">{{ errors.email }}</span>
          </div>
          <div class="form-group" v-if="selected === 'organisation'">
            <label for="organization">
              <span class="subtitle"><span class="required">*</span> Organization</span>
            </label>
            <input
                type="text"
                id="organization"
                v-model="organization"
                @input="validateOrganization"
                @blur="validateOrganization"
                placeholder="Organization"
            />
            <span class="error" v-if="errors.organization">{{ errors.organization }}</span>
          </div>
            <button id="SubscribeInBlock" type="submit">Subscribe</button>
        </form>
      </div>
    </div>
    <div v-else class="success-message">
      <div class="success-block">
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
          <path d="M23.2666 39.2667L42.0666 20.4667L38.3332 16.7334L23.2666 31.8L15.6666 24.2L11.9333 27.9334L23.2666 39.2667ZM26.9999 53.6667C23.311 53.6667 19.8444 52.9667 16.5999 51.5667C13.3555 50.1667 10.5333 48.2667 8.13325 45.8667C5.73325 43.4667 3.83325 40.6445 2.43325 37.4C1.03325 34.1556 0.333252 30.6889 0.333252 27C0.333252 23.3111 1.03325 19.8445 2.43325 16.6C3.83325 13.3556 5.73325 10.5334 8.13325 8.13337C10.5333 5.73337 13.3555 3.83337 16.5999 2.43337C19.8444 1.03337 23.311 0.333374 26.9999 0.333374C30.6888 0.333374 34.1555 1.03337 37.3999 2.43337C40.6444 3.83337 43.4666 5.73337 45.8666 8.13337C48.2666 10.5334 50.1666 13.3556 51.5666 16.6C52.9666 19.8445 53.6666 23.3111 53.6666 27C53.6666 30.6889 52.9666 34.1556 51.5666 37.4C50.1666 40.6445 48.2666 43.4667 45.8666 45.8667C43.4666 48.2667 40.6444 50.1667 37.3999 51.5667C34.1555 52.9667 30.6888 53.6667 26.9999 53.6667ZM26.9999 48.3334C32.9555 48.3334 37.9999 46.2667 42.1333 42.1334C46.2666 38 48.3332 32.9556 48.3332 27C48.3332 21.0445 46.2666 16 42.1333 11.8667C37.9999 7.73337 32.9555 5.66671 26.9999 5.66671C21.0444 5.66671 15.9999 7.73337 11.8666 11.8667C7.73325 16 5.66658 21.0445 5.66658 27C5.66658 32.9556 7.73325 38 11.8666 42.1334C15.9999 46.2667 21.0444 48.3334 26.9999 48.3334Z" fill="#1AB788"/>
        </svg>
        <span class="success-title">Success</span>
        <span class="success-subtitle">You are subscribed, thanks!</span>
      </div>
    </div>
  </div>
</template>

<script>

import { subscribeRefugee, subscribeOrganization } from '@/main';
export default {
  data() {
    return {
      subscriptionType: 'Refugee',
      firstName: '',
      lastName: '',
      email: '',
      organization: '',
      isSubscribed: false,
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
      },
      selected: 'refugee',
    };
  },
  methods: {
    validateFirstName() {
      this.errors.firstName = this.firstName ? '' : 'First name is required.';
    },
    validateLastName() {
      this.errors.lastName = this.lastName ? '' : 'Last name is required.';
    },
    validateOrganization() {
      if (this.selected === 'organisation') {
        this.errors.organization = this.organization ? '' : 'Organization is required.';
      } else {
        this.errors.organization = '';
      }
    },
    validateEmail() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      if (!this.email) {
        this.errors.email = 'Email is required.';
      } else if (!re.test(this.email)) {
        this.errors.email = 'Invalid email address.';
      } else {
        this.errors.email = '';
      }
    },
    async subscribe() {
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      this.validateOrganization();

      if (!this.errors.firstName && !this.errors.lastName && !this.errors.email && !this.errors.organization) {
        try {
          if (this.selected === 'refugee') {
            await subscribeRefugee({
              email_address: this.email,
              status: 'subscribed',
              merge_fields: {
                FNAME: this.firstName,
                LNAME: this.lastName
              }
            });
          } else if (this.selected === 'organisation') {
            await subscribeOrganization({
              email_address: this.email,
              status: 'subscribed',
              merge_fields: {
                FNAME: this.firstName,
                LNAME: this.lastName,
                ORGANISATION: this.organization
              }
            });
          }
          this.isSubscribed = true;
        } catch (error) {
          console.error('Subscription error:', error);
        }
      }
    }
  }
};
</script>

<style scoped>
.containerSub {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 130px;
  margin-top: 120px;

  @media screen and (max-width: 1224px) {
    flex-direction: column;
    gap: 32px;
  }
}

.subscribe-block {
  width: 32%;
  color: $main-color;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    font-size: calc(var(--main-font-size) + 32px);
    margin-bottom: 13px;
    line-height: 124%;
    color: #184892;
  }

  p {
    font-size: calc(var(--main-font-size) + 2px);
    margin-bottom: 30px;
    font-weight: 400;
  }

  @media (max-width: 1224px) {
    width: 100%;
    margin-bottom: 32px;

    h1 {
      font-size: calc(var(--main-font-size) + 8px);
    }

    p {
      font-size: var(--main-font-size);
    }

    img {
      width: 100%;
      height: auto;
      align-self: center;
    }
  }
}

.subscription-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 496px;
  height: auto;
  padding: 32px;
  gap: 32px;
  border-radius: 8px;
  background: #FFF;

  @media screen and (max-width: 1224px) {
    padding: 32px;
    width: 100%;
    margin: 0;
  }
}

.title {
  color: #1C1B1F;
  font-family: Poppins bold, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
}

.subtitle {
  color: #000000E0;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.subtitle.variables {
  font-size: 16px;
  line-height: 24px; /* 150% */
}

.radio-group {
  display: flex;
  align-items: center;
}

.radio-group label {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.radio-group input[type="radio"] {
  display: none;
}

.radio-group svg {
  margin-right: 8px;
}

.form-group {
  margin-bottom: 32px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.subtitle .required {
  color: red;
  margin-right: 5px;
}

input[type="text"],
input[type="email"] {
  width: calc(100% - 24px);
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;

  @media screen and (max-width: 1224px) {
    width: 100%;
  }

}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #034694;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #023d73;
}

.success-message {
  display: flex;
  align-items: center;
  width: 100%;
}

.success-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 496px;
  height: 575px;
  padding: 32px;
  gap: 8px;
  border-radius: 8px;
  background: #FFF;
}

.success-title {
  color: #1C1B1F;
  font-family: Poppins bold, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
}

.success-subtitle {
  color: #1AB788;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
</style>
