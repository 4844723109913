import Cookies from 'js-cookie';

const changeLang = async (code, i18nInstance) => {
    Cookies.set('selectedLanguage', code, {expires: 7});
    i18nInstance.locale = code;
}

const initLanguage = async (i18nInstance) => {
    let langCode = Cookies.get('selectedLanguage');
    if (langCode) {
        await changeLang(langCode, i18nInstance);
    } else {
        await changeLang("en", i18nInstance);
    }
}
export default {
    changeLang,
    initLanguage
}