<template>
  <div class="container content">
    <div class="filter-group">
      <SelectComponent class="filter" :options="optionsCategories" @categoryChanged="filterNews"/> <!-- Добавлено -->
    </div>
    <div class="box-news">
      <div v-for="(newsSubject, index) in filteredNews" class="news">
        <img alt="" :src="getFile(newsSubject.logo)">
        <div class="news-info">
          <p class="publication-date">
            {{ formatPublicationDate(newsSubject.createdAt) }}
          </p>
          <p class="title">
            {{newsSubject.title}}
          </p>
          <p class="description">
            {{newsSubject.description}}
          </p>
        </div>
        <blue-button class="blue-button" :title="readMore" @click="navigateToNewsReadMore(newsSubject)"/>
      </div>
    </div>
  </div>
</template>

<script>
import SelectComponent from "@/components/SelectComponent.vue";
import BlueButton from "@/components/BlueButton.vue";
import axios from "axios";
import { format } from 'date-fns';

export default {
  name: 'NewsPage',
  components: {BlueButton, SelectComponent},
  data() {
    return {
      news: [],
      filteredNews: [],
      selectedCategory: 1,
      optionsCategories: [
        {value: 0, text: "All Categories"},
        {value: 1, text: "Client Success Stories" },
        {value: 2, text: "Additional Resources" },
        {value: 3, text: "Blogs" },
        {value: 4, text: "Most Recent" },
        {value: 5, text: "News" },

      ],
      readMore: this.$t('readMore')
    };
  },
  methods: {
    filterNews(categoryId) {
      this.selectedCategory = categoryId;
      if (categoryId === 0) {
        this.filteredNews = this.news;
      } else {
        this.filteredNews = this.news.filter(newsSubject =>
            newsSubject.categories && newsSubject.categories.length > 0 && newsSubject.categories[0].id === categoryId
        );
      }
    },

    formatPublicationDate(dateString) {
      return format(new Date(dateString), 'EEEE, MMMM d, yyyy'); // форматирование
    },

    navigateToNewsReadMore(newsSubject) {
      if (newsSubject.redirect) {
        window.open(newsSubject.redirect, '_blank');
      } else {
        this.$router.push({ path: `/read-more/${newsSubject.url}` });
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
      }
    },

    getFile(url){
      return process.env.VUE_APP_API_URL + url
    }
  },

  mounted() {
    axios.get(process.env.VUE_APP_API_URL+"/news")
        .then((response)=>{
          this.news = response.data
          this.filterNews(this.selectedCategory);  // Добавьте эту строку
        })
  }
}
</script>

<style scoped lang="scss">
.container {
  @media (max-width: 1224px) {
    padding: 0 1rem;
  }
}

.content {
  min-height: 100vh;
  text-align: start;
}

.filter-group {
  margin: 4rem 0 1.5rem;
  display: flex;
  gap: 5px;

  .filter {
    width: 392px;
    @media (max-width: 1224px) {
      width: 100%;
    }
  }
}

.box-news {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem 1rem;
  margin-bottom: 8rem;

  .news {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    img {
      width: 600px;
      height: 336px;
      object-fit: cover;
      max-width: 100%;
    }

    .news-info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .publication-date {
        color: #373737;
        font-size: calc(var(--main-font-size) - 2px);
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        direction: ltr;
      }

      .title {
        color: #184892;
        font-size: calc(var(--main-font-size) + 2px);
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        direction: ltr;
      }

      .description {
        color: #000;
        font-size: var(--main-font-size);
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        direction: ltr;
      }
    }


  }

  .blue-button {
    width: 143px;
    font-size: var(--main-font-size);
    padding: 8px 20px;
    font-weight: 400;
    text-transform: none!important;
  }
}

@media only screen and (max-width: 768px) {

  .box-news {
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
  }
}

</style>