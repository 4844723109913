<template>
  <div class="card"
       @mouseenter="startTracking"
       @click="showModal($event)"
       @mousemove="trackMousePosition"
       @mouseleave="stopTracking" id="op-card">
    <img :alt="card.title" :src="card.icon">
    <div class="text-bold d-flex align-items-center title">
      {{ card.title }}
      <img :alt="$t('right')" src="@/assets/icons/arrow_right_alt.svg">
    </div>
    <Modal
        :is-modal-visible="isModalVisible"
        @close="closeModal"
    >
      <template v-slot:modal>
        <div class="modal-content modal-card">
          <p>{{card.title}}</p>
          <ul>
            <li v-for="item in card.opportunities">
              {{item}}
            </li>
          </ul>
        </div>
      </template>

    </Modal>
  </div>




</template>

<script>
import CardPopover from "@/components/CardPopover.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: 'OpportunityCard',
  components: {Modal, CardPopover},
  props: {
    card: null,
  },
  data() {
    return {
      isShowPopover: false,
      isModalVisible: false,
      mouseStoppedTimer: null,
      isMouseStopped: false,
      timer: null,
      timeOut: null
    }
  },


  methods: {
    startTracking() {
      if (window.innerWidth < 1224) {
        return;
      }
      clearInterval(this.timer);

      this.timer = setInterval(() => {
        if (this.isMouseStopped) {
          // console.log("Mouse has stopped");
          this.$emit('mouse-stopped', { x: this.mouseX, y: this.mouseY });
        } else {
          this.isMouseStopped = true; // Устанавливаем флаг только если мышь двигалась
        }
      }, 100); // Проверка каждую секунду

      this.$emit('setCurrentCard', this.card);
    },

    stopTracking() {
      clearInterval(this.timer);
      this.isMouseStopped = false;
      this.$emit('mouse-leave');
    },

    trackMousePosition(event) {
      if (window.innerWidth < 1224) {
        return;
      }
      if (this.isMouseStopped) {
        return;
      }
      const container = document.querySelector("#container-cards")
      const x = event.clientX - container.getBoundingClientRect().left - 315;
      const y = event.clientY - container.getBoundingClientRect().top;
      this.mouseX = x;
      this.mouseY = y;
      // console.log(`X: ${x}, Y: ${y}`);
      this.isMouseStopped = true;
    },
    showModal(event) {
      if (window.innerWidth > 1224) {
        return;
      }
      if (event.target.id === 'op-card' || event.target.parentElement.id === 'op-card') {
        this.isModalVisible = true;
      }

    },
    closeModal() {
      // console.log('close')
      this.isModalVisible = false;
      // console.log(this.isModalVisible)
    }
  },

  // methods: {
  //   scrollHandler() {
  //     this.isShowPopover = false;
  //   },
  //
  //   // showPopover() {
  //   //   this.$emit('setCurrentCard', this.card);
  //   //
  //   //   if (event.target.id !== 'op-card' || window.innerWidth < 1224) {
  //   //     return;
  //   //   }
  //   //
  //   //   const el = this.$refs.popover.$el;
  //   //   const rect = el.getBoundingClientRect();
  //   //
  //   //   const left = event.clientX;
  //   //   const top = event.clientY;
  //   //   const height = el.clientHeight;
  //   //   const width = el.clientWidth;
  //   //
  //   //
  //   //   let posX = left - width;
  //   //   let posY = top;
  //   //
  //   //   if (posX < 0) {
  //   //     posX = width / 2 - 20;
  //   //     this.$refs.popover.positionXClass = 'left'
  //   //   }
  //   //
  //   //
  //   //   if (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.top <= el.clientHeight) {
  //   //     this.$refs.popover.positionYClass = 'top';
  //   //   } else {
  //   //     posY = posY - height; - ne nado
  //   //     this.$refs.popover.positionYClass = 'bottom';
  //   //   }
  //   //
  //   //   /*if (rect.right <= (window.innerWidth || document.documentElement.clientWidth) && rect.left <= el.clientWidth) {
  //   //     this.$refs.popover.positionXClass = 'left'
  //   //   } else {
  //   //     this.$refs.popover.positionXClass = 'right'
  //   //   }*/
  //   //
  //   //   el.style.left = `${posX}px`;
  //   //   el.style.top = `${posY}px`
  //   //
  //   //
  //   //   this.isShowPopover = true;
  //   // },
  //   hidePopover() {
  //     clearInterval(this.timer);
  //     this.isMouseStopped = false;
  //     this.isShowPopover = false;
  //   },
    showModal(event) {
      if (window.innerWidth > 1224) {
        return;
      }
      if (event.target.id === 'op-card' || event.target.parentElement.id === 'op-card') {
        this.isModalVisible = true;
      }

    },
  //   closeModal() {
  //     this.isModalVisible = false;
  //   },
  //   handleMouseOver(event) {
  //     // Установим интервал проверки на 100 миллисекунд
  //     const checkInterval = 100;
  //
  //     if (this.mouseStoppedTimer) {
  //       clearInterval(this.mouseStoppedTimer);
  //     }
  //
  //     this.mouseStoppedTimer = setInterval(() => {
  //       // Здесь можно получить текущее положение курсора мыши
  //       const mouseX = event.clientX;
  //       const mouseY = event.clientY;
  //
  //       // Если положение не изменилось, считаем, что мышь остановилась
  //       if (!this.isMouseStopped && this.lastMouseX === mouseX && this.lastMouseY === mouseY) {
  //         this.isMouseStopped = true;
  //         console.log('Мышь остановилась');
  //
  //         // if (this.mouseStoppedTimer) {
  //         //   clearInterval(this.mouseStoppedTimer);
  //         // }
  //         // Вы можете добавить свой код для действий, когда мышь остановилась
  //       }
  //
  //       // Если положение изменилось, сбрасываем флаг и сохраняем новые координаты
  //       if (this.lastMouseX !== mouseX || this.lastMouseY !== mouseY) {
  //         this.isMouseStopped = false;
  //         this.lastMouseX = mouseX;
  //         this.lastMouseY = mouseY;
  //       }
  //     }, checkInterval);
  //   },
  //   startTracking() {
  //     this.timer = setInterval(() => {
  //       if (this.isMouseStopped) {
  //         console.log("Mouse has stopped");
  //       }
  //       this.isMouseStopped = false;
  //     }, 1000); // Проверка каждую секунду
  //   },
  //   trackMousePosition(event) {
  //     // Здесь вы можете получить координаты мыши внутри элемента
  //     const x = event.clientX - event.target.getBoundingClientRect().left;
  //     const y = event.clientY - event.target.getBoundingClientRect().top;
  //
  //     console.log(`X: ${x}, Y: ${y}`);
  //   }
  // },

}
</script>

<style lang="scss" scoped>
.card {
  width: 384px;
  height: 163px;
  background-color: rgba(146, 197, 235, 1);
  border-radius: 8px;
  box-shadow: -8px 5px 0px 0px rgba(181, 176, 216, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: underline;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 60px solid white;
    border-left: 60px solid rgba(146, 197, 235, 1);
    width: 0;
  }

  @media (max-width: 1224px) {
    width: 100%;
    height: 66px;
    flex-direction: row;
    gap: 8px;

    &:before {
      border-top: 30px solid white;
      border-left: 30px solid rgba(146, 197, 235, 1);
    }

    img {
      width: 32px;
      height: 32px;
    }

    .title {
      font-size: calc(var(--main-font-size) - 2px);;
    }
  }
}
</style>