<template>
  <footer>
    <div class="content container mp">
      <div class="data-block">
        <div class="first-block">
          <div class="services">
            <h4 class="text-bold" :class="[{'align': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">{{ $t('serviceProvidedBy') }}</h4>
            <div class="images" :class="[{'dir': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
              <a href="https://www.worldjewishrelief.org/" target="_blank">
                <img :alt="$t('wjr')" src="@/assets/images/WJRnew.png">
              </a>
              <a href="https://wearetern.org/" target="_blank">
                <img :alt="$t('tern2')" src="@/assets/images/tern.png">
              </a>
              <a href="https://www.rescue.org/uk" target="_blank">
                <img :alt="$t('irc')" src="@/assets/images/irc-big.png">
              </a>
            </div>
          </div>

          <div class="terms-and-condition">
            <div class="link-group" :class="[{'link-group-ar': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
              <h4 class="text-bold">
                <a href="https://www.rescue.org/uk/respecting-your-privacy" target="_blank">{{ $t('termsAndConditions') }}</a>
              </h4>
              <h4 @click="openPDF" class="text-bold">
                <a >{{ $t('repDataPrivacyPolicy') }}</a>
              </h4>
            </div>


            <p style="direction: ltr">{{ $t('internationalRescueCommitteeUkIsACharitableCompany') }}</p>

          </div>
        </div>
        <div class="links" :class="[{'dir': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
          <router-link class="link" to="/">{{ $t('home') }}</router-link>

          <a class="link" @click="scrollToForm">{{ $t('applyToday3') }}</a>

          <router-link class="link" to="/refer-someone">{{ $t('referSomeone') }}</router-link>

          <router-link class="link" to="/news">{{ $t('news') }}</router-link>

          <router-link class="link" to="/about-us">{{ $t('aboutUs') }}</router-link>

          <router-link class="link" to="/contact">{{ $t('contactUs') }}</router-link>

          <a class="link" href="https://help.rescue-uk.org/refugees-donate" target="_blank" >Donate</a>

          <router-link class="link" :to="{ path: '/contact', hash: '#subscribeBlock', query: { scroll: 'true' } }">{{ $t('subscribe') }}</router-link>
        </div>
      </div>
      <div class="founded-by">
        <img :alt="$t('foundedBy')" src="@/assets/images/founded-by.png">
      </div>
    </div>

    <div class="top-dots">
    </div>
    <div class="bottom-dots">
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: ['selectedLanguage'],
  methods: {
    scrollToForm() {
      if (this.$route.name !== 'home') {
        this.$router.push({path: '/'});
        setTimeout(() => {
          document.getElementById('form')?.scrollIntoView({behavior: 'smooth'})
        }, 100);
      }
      document.getElementById('form').scrollIntoView({behavior: 'smooth'})
    },
    openPDF() {
      window.open('/REP-Data-Privacy-Notice.pdf', '_blank').focus();
    }

  }
}
</script>

<style lang="scss" scoped>
.dir{
  direction: rtl;
}

.link-group-ar{
  width: 100%;
  justify-content: end!important;
  text-align: end;
}

.align{
  text-align: end!important;
}
.data-block {
  display: flex;
  width: calc(100% - 392px);
  gap: 158px;

  @media (max-width: 1224px) {
    width: 100%;
    gap: 0;
    flex-direction: column-reverse;
  }
}

footer {
  width: 100%;
  height: 433px;
  display: flex;
  align-items: center;
  background-color: $main-color;
  position: relative;

  .top-dots {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 31px;
    top: 0;
    background-image: url('@/assets/images/footer-dots.png');
  }

  .bottom-dots {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 31px;
    bottom: 3px;
    background-image: url('@/assets/images/footer-dots.png');
  }

  @media (max-width: 1224px) {
    height: auto;
  }
}

.content {
  height: 260px;

  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  @media (max-width: 1224px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 50px 16px;
    padding-top: 95px;
  }
}

.first-block {
  display: flex;
  width: 395px;
  flex-direction: column;

  @media (max-width: 1224px) {
    width: 100%;
    order: 1;
  }
}

.services {
  h4 {
    margin-bottom: 16px;
    //text-align: left;
  }

  .images {
    display: flex;
    gap: 11px;
    a{
      width: fit-content;
    }

    @media (max-width: 1224px) {
      width: 100%;
      //justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
    }

    img {
      width: 124px;
      height: 65px;
      object-fit: contain;

      @media (max-width: 425px) {
        width: 124px;
        height: 65px;
      }
    }
  }
}

.terms-and-condition {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  text-align: start;


  .link-group {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    a{
      cursor: pointer;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      margin-bottom: 1.5rem;
    }
  }


  h4 {
    text-decoration: underline;
    margin-bottom: 16px;

    a {
      color: white;
    }

    @media (max-width: 576px) {
      margin-bottom: 0;
    }
  }

  p {
    font-size: calc(var(--main-font-size) - 2px);
    line-height: 21px;
    font-style: italic;
  }

  @media (max-width: 1224px) {
    margin-bottom: 32px;
    align-items: start;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1224px) {
    margin-bottom: 32px;
    align-items: start;
  }
}

.founded-by {
  width: 392px;
  height: 101px;
  display: flex;

  @media (max-width: 1224px) {
    width: 100%;
    height: 85px;
    align-items: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
