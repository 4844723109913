import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from 'vue-i18n';
import enMessages from './locales/en.json';
import arMessages from './locales/ar.json';
import prsMessages from './locales/prs.json';
import psMessages from './locales/ps.json';
import axios from "axios";
import VueGtag from "vue-gtag";


Vue.use(VueI18n);

Vue.use(VueGtag, {
    config: {id: "G-ZE87FTLW4N"}
});

function loadLocaleMessages(locale) {
    return axios.get(process.env.VUE_APP_API_URL + `/locales/${locale}`)
        .then(response => {
            i18n.setLocaleMessage(locale, response.data);
        })
        .catch(error => {
            console.error("Error during loading locale:", error);
        });
}

const i18n = new VueI18n({
    locale: 'en',
    messages: {},
});
export { i18n };

Vue.config.productionTip = false;

Promise.all([
    loadLocaleMessages('en'),
    loadLocaleMessages('ar'),
    loadLocaleMessages('ps'),
    loadLocaleMessages('prs'),
]).then(() => {
    new Vue({
        i18n,
        router,
        render: (h) => h(App),
    }).$mount("#app");
});


export function fetchStories() {
    return axios.get(process.env.VUE_APP_API_URL + '/stories')
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error("Error during fetching stories:", error);
            throw error; // Выбрасываем ошибку, чтобы вызывающая сторона могла обработать её
        });
}

export function fetchBadges() {
    return axios.get(process.env.VUE_APP_API_URL + '/badges')
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error("Error during fetching badges:", error);
        });
}


export async function subscribeRefugee(refugeeForm) {
    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/mailchimp', refugeeForm, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating leaflet:', error);
        throw error;
    }
}

export async function subscribeOrganization(organisationForm) {
    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/mailchimp/organisation', organisationForm, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating leaflet:', error);
        throw error;
    }
}
