<template>
  <div id="app" :class="[{'align': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
    <Header class="z-index"/>
    <router-view :selectedLanguage="selectedLanguage"/>
    <div v-if="showSubscribeBlock" class="popup-overlay">
      <div class="popup-container">
        <button @click="showSubscribeBlock = false" class="close-button">✖</button>
        <subscribe-block @close="showSubscribeBlock = false" />
      </div>
    </div>
    <Footer :selectedLanguage="selectedLanguage"/>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Home from "@/views/Home.vue";
import Footer from "@/components/Footer.vue";
import SubscribeBlock from "@/components/subscribeBlock.vue";
import language from "@/services/language";
import {EventBus} from "@/services/eventBus";

export default {
  components: {
    Header,
    Home,
    Footer,
    SubscribeBlock,
  },

  data() {
    return {
      selectedLanguage: 'en',
      showSubscribeBlock: false,
    };
  },

  mounted() {
    this.selectedLanguage = this.$i18n.locale
    if (EventBus._events && EventBus._events['languageChangedHeader']) {
      EventBus.$on('languageChangedHeader', (newLanguage) => {
        this.selectedLanguage = newLanguage;
      });
    }
      EventBus.$on('languageChangedHome', (language) => {
      this.selectedLanguage = language;
    });
    console.log(this.selectedLanguage);

    // setTimeout(() => {
    //   this.showSubscribeBlock = true;
    // }, 15000);
  },


  // methods: {
  //   // Обработка изменения языка в App.vue
  //   handleLanguageChange(language) {
  //     this.selectedLanguage = language;
  //   },
  //
  //   // Другие методы
  // },

  async created() {
    await language.initLanguage(this.$i18n)
  }
}

// Temporary function for future implementation
function changeFontSizes(elements) {
  Array.from(elements).forEach(x  => {
    const fontSize = window.getComputedStyle(x).getPropertyValue('font-size');

    if (fontSize) {
      x.style.fontSize = '50px';
    }
    if (x.children.length) {
      changeFontSizes(x.children)
    }
  })
}

</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background: #f0f4fa;
  padding: 20px;
  border-radius: 10px;
  width: 70%;
  max-width: 1128px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .containerSub {
    margin: 0;
  }
}

@media (max-width: 1224px) {
  .popup-container {
    width: 90%;
    height: 90vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    overflow: auto;
  }
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

.popup-container .content-left {
  width: 50%;
  padding-right: 20px;
}

.popup-container .content-right {
  width: 50%;
}

.popup-container img {
  max-width: 100%;
  border-radius: 10px;
}


.align{
  p, span, h1, h2, h3, h4, h5, h6, a, router-link, li{
    text-align: start;
    direction: rtl;
  }

}

:root {
  --main-font-size: 16px;
  --font-family: 'Poppins', sans-serif;
}

@font-face {
  font-family: 'Dyslexic';
  src: url('@/assets/styles/fonts/OpenDyslexic/OpenDyslexic-Regular.otf');
}

.z-index{
  z-index: 9;
}

.text-spacing-1 {
  word-spacing: .16em!important;
  letter-spacing: .12em!important;
}

.text-spacing-2 {
  word-spacing: .32em!important;
  letter-spacing: .24em!important;
}

.text-spacing-3 {
  word-spacing: .48em!important;
  letter-spacing: .36em!important;
}

.container {
  width: 1224px;
  margin: 0 auto;

  @media (max-width: 1224px) {
    width: 100% !important;
  }
}

.text-bold {
  font-weight: 600;
}

.text-default {
  font-weight: 400;
}

.btn-primary {
  background-color: $main-color;
  color: #ffffff;
  text-transform: uppercase;
  padding: 16px 32px;
  outline: none;
  border: 2px solid $main-color;
  border-radius: 8px;
  font-weight: 700;
  font-family: var(--font-family);
  transition: all .2s ease-in-out;

  &:hover {
    background-color: #0075BC;
    cursor: pointer;
    border-color: #0075BC;
  }

  &:focus {
    background-color: $main-color;
    border: 2px solid $main-color;
  }

  &.disabled {
    border-color: rgba(19, 12, 39, 0.16);
    background-color: rgba(19, 12, 39, 0.16);
    color: rgba(19, 12, 39, 0.3);
  }
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.btn-secondary {
  background: transparent;
  color: $main-color;
  text-transform: uppercase;
  padding: 16px 20px;
  outline: none;
  border: 2px solid $main-color;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  &:hover {
    cursor: pointer;
    border-color: #0075BC;
    color: #0075BC
  }

  &:focus {
    background-color: $main-color;
    color: #ffffff;
    border-color: $main-color;
  }

  &.disabled {
    border-color: rgba(19, 12, 39, 0.16);
    background-color: rgba(19, 12, 39, 0.16);
    color: rgba(19, 12, 39, 0.3);
  }
}

.link {
  color: #ffffff;
  text-decoration: none;
  width: fit-content;
  font-weight: 600;
  font-size: calc(var(--main-font-size) - 2px);
  position: relative;
  transition: hover 0.25s linear;

  &::after {
    content: '';
    position: absolute;
    background-color: #FFC72C;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transition: transform 0.25s ease-out;
    @media (max-width: 700px){
      background: none;
    }
  }

  &:hover {
    color: #FFC72C;
    cursor: pointer;
    @media (max-width: 700px){
      color: inherit;
      position: inherit;
    }

    &::after {
      transform: scaleX(1);
    }
  }
}


.radio-btn {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: $main-color;
  width: 24px;
  height: 24px;
  border: 1px solid $main-color;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: scale(0);
    transition: 100ms transform ease-in-out;
    box-shadow: inset 1em 1em $main-color
  }

  &:checked::before {
    transform: scale(1);
  }

  @media (max-width: 1224px) {
    width: 16px;
    height: 16px;

    &::before {
      width: 16px;
      height: 16px;
    }
  }
}

.input {
  padding: 8px 12px;
  font-size: var(--main-font-size);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  height: 40px;

  &:focus {
    outline: none;
  }

  @media (max-width: 1224px) {
    width: 100%;
  }
}

.checkbox {
  appearance: none;
  margin: 0;
  font: inherit;
  color: #ffffff;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #ffffff;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
  }

  &:checked {
    background-color: $main-color;
  }
}

.w-100 {
  width: 100%;
}

.banner {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 486px;
  display: flex;
  align-items: center;
  background-image: url("@/assets/images/main-banner-new+.png");
  background-position: 60% 100%;
}

.banner-content {
  display: flex;
  flex-direction: column;
  color: $main-color;

  h1 {
    font-size: calc(var(--main-font-size) + 32px);
  }

  p {
    margin-top: 12px;
    color: #000;
    font-size: calc(var(--main-font-size) + 2px);
  }

  .buttons {
    display: flex;
    gap: 24px;
    margin-top: 64px;

    .btn-primary {
      width: 288px;
    }

    .btn-secondary {
      width: 194px;
    }
  }
}

.modal-content {
  color: #ffffff;
  font-size: var(--main-font-size);
  line-height: 24px;
  padding-right: 22px;
  font-weight: 400;
  margin-bottom: 48px;
  overflow: auto;

  @media (max-height: 760px) {
    margin-bottom: 0;
  }

  @media (max-width: 1224px) {
    margin-bottom: 0;
  }

  ul {
    width: 100%;
    margin-top: 0;
    display: block;

    li {
      font-weight: 400;

      &::before {
        content: "-";
      }
    }
  }

  .block {
    margin-bottom: 16px;
  }
}

.modal-card {
  li {
    &::before {
      content: '•'!important;
    }
  }
}

.text-bold-700 {
  font-weight: 700;
}
</style>


