<template>
  <div>

    <div class="bann">
      <img class="banner-logo" :src="getFile(article.logo)" alt="banner-logo">
      <img alt="slice" class="banner-preset" src="@/assets/icons/slice-top.png">
      <div class="mobile-background"></div>
      <div class="banner-title-wrapper">
        <span class="banner-title">
          {{ article.badgeTitle }}
        </span>
      </div>

    </div>
    <div class="container">
      <div class="container-all-news">
        <div class="news-header">
          <p class="title">
            <span>{{ $t('september182023') }}</span>
<!--            {{ article.title }}-->
          </p>
        </div>
        <div class="body-news" ref="articleBody" v-html="cleanHtml(article.description)"></div>
      </div>

      <faq-component v-if="article.faq.length > 0" :accordion-items="article.faq"/>

      <event-component v-if="article.Events.length > 0" :events="events"/>

      <top-stories v-if="article.Story.length > 0" :stories="article.Story"/>

      <div class="leaflets" v-if="article.Leaflet.length > 0">
        <p class="title">Leaflets</p>
        <leaflets-component :leaflets="article.Leaflet"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sanitizeHTML from "sanitize-html";
import EventComponent from "@/components/eventComponent.vue";
import LeafletsComponent from "@/components/leafletsComponent.vue";
import TopStories from "@/components/TopStories.vue";
import FaqComponent from "@/components/faqComponent.vue";

export default {
  name: "ArticlePage",
  components: {FaqComponent, TopStories, LeafletsComponent, EventComponent},
  props: ['url'],
  data() {
    return {
      article: {},
      events: [],
      urlFromUrl: this.url,
      descriptions: [],
    }
  },
  watch: {
    article: {
      handler() {
        this.$nextTick(() => {
          this.wrapImages();
        });
      },
      deep: true,
    },
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}/articles/${this.urlFromUrl}`)
        .then((response) => {
          this.article = response.data;
          this.events = this.article.Events;
        });
  },
  methods: {
    getFile(url) {
      return process.env.VUE_APP_API_URL + url;
    },
    getBanner(url) {
      return {
        backgroundImage: process.env.VUE_APP_API_URL + url
      }
    },
    cleanHtml(unsafeContent) {
      return sanitizeHTML(unsafeContent, {
        allowedTags: [
          "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
          "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
          "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
          "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
          "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
          "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
          "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "img"
        ],
        allowedAttributes: {
          a: ['href', 'name', 'target'],
          img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading']
        },
      });
    },
    wrapImages() {
      const container = this.$refs.articleBody;
      if (!container) return;
      const images = container.querySelectorAll('img:not(.wrapped)');
      images.forEach(img => {
        console.log("img");
        const wrapper = document.createElement('div');
        wrapper.classList.add('image-wrapper');
        img.parentNode.insertBefore(wrapper, img);

        const innerDiv = document.createElement('div');
        innerDiv.classList.add('inner-image-wrapper');
        wrapper.appendChild(innerDiv);
        innerDiv.appendChild(img);

        if(img.alt !== ''){
          const descriptionDiv = document.createElement('div');
          descriptionDiv.classList.add('image-description');
          const descriptionText = document.createElement('p');
          descriptionText.innerHTML = img.alt.replace(/\n/g, '<br>');
          descriptionDiv.appendChild(descriptionText);
          wrapper.appendChild(descriptionDiv);
        }

        img.classList.add('wrapped');
      });
    }
  }
}
</script>


<style lang="scss" scoped>

@import "@/assets/styles/vdeep.scss";

.bann {
  display: flex;
  height: 482px;
  width: 100%;
  @media (max-width: 768px) {
    height: 200px;
  }
  .banner-preset {
    position: absolute;
    z-index: 2;
    left: 0;
    @media (max-width: 1380px) {
      width: 70px;
      right: 0;
      transform: rotate(90deg);
      left: auto;
    }
  }
  .banner-logo {
    display: flex;
    position: absolute;
    z-index: 1;
    right: 0;
    height: 482px;
    width: auto;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .mobile-background {
    position: absolute;
    display: none;
    height: 482px;
    width: 100%;
    background-color: #92C5EB;
    z-index: 1;
    overflow: hidden;
    @media (max-width: 1380px) {
      display: flex;
    }
    @media (max-width: 768px) {
      display: flex;
      height: 200px;
    }
  }

  .banner-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-wrap: normal;
    width: 25%;
    height: 100%;
    margin-left: 5%;


    @media (min-width: 1700px) {
      width: 40%;
    }

    @media (min-width: 1500px) {
      width: 35%;
    }

    @media (max-width: 1380px) {
      width: 100%;
    }
  }

  .banner-title {
    position: absolute;
    z-index: 3;
    color: #034694;
    font-family: Poppins, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 59.52px */
    letter-spacing: 1.44px;
    text-decoration-line: underline;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
}

.container {
  padding: 0 1rem;
  text-align: start;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  .leaflets {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      color: #024694;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 0.72px;
      margin: 0;
    }
  }
}

.container-all-news {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  //margin: 4rem 0 7.5rem;

  .news-header {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 4rem;

    .title {
      color: #024694;
      font-size: 48px;
      font-style: normal;
      //font-weight: bold;
      line-height: 124%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      direction: ltr;

      span {
        color: #373737;
        font-size: calc(var(--main-font-size) - 2px);
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .chapter {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .chapter-title {
      color: #184892;
      font-size: calc(var(--main-font-size) + 2px);
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }

    .chapter-text {
      color: #000;
      font-size: calc(var(--main-font-size) + 2px);
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>