<template>
  <div class="modal-backdrop" v-show="isModalVisible" @click="backdropClick($event)" id="backdrop">
    <div class="modal">
      <slot name="modal"></slot>

      <a class="close" @click="close">
        <img src="@/assets/icons/close.svg" :alt="$t('close')">
        {{ $t('closeThisWindow') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    isModalVisible: false
  },
  methods: {
    close() {
      this.$emit('close');
    },
    backdropClick(event) {
      if (event.target.id === 'backdrop') {
        this.close();
      }
    }
  },
  watch: {
    isModalVisible: (value) => {
      const body = document.body;
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

      if (value) {
        body.style.overflowY = 'hidden';
        body.style.setProperty('--scroll-bar-width', `${scrollBarWidth}px`);
        body.style.marginRight = `var(--scroll-bar-width)`;
      } else {
        body.style.overflowY = 'auto';
        body.style.marginRight = '0px';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: $main-color;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 32px;
  max-width: 735px;
  z-index: 1002;
  max-height: 745px;
  position: relative;

  @media (max-width: 1224px) {
    width: 100%;
    padding: 24px;
    margin: 0 1rem;
  }

  @media (max-height: 760px) {
    max-height: 500px;
    padding-bottom: 10px;
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
}
</style>