<template>
  <header class="header">
    <google-translate style="display: none"/>
    <div class="container">
      <BreadCrumbs/>

      <div class="nav">
        <div class="founded-by" @click="hideBlock">
          <img :alt="$t('foundedBy2')" class="logo" src="@/assets/images/founded-by.png">
        </div>

        <router-link class="rep-program" to="/">
          <img :alt="$t('rep')" class="logo" src="@/assets/images/rep-logo.png" @click="hideBlock">
        </router-link>

        <ul class="nav-list">
          <li @click="hideBlock">
            <router-link class="link" to="/about-us">{{ $t('aboutUs') }}</router-link>
          </li>

          <li>
            <a class="link" @click="scrollToForm">{{ $t('applyToday2') }}</a>
          </li>

          <li>
            <router-link class="link" to="/refer-someone">{{ $t('referSomeone') }}</router-link>
          </li>

          <li>
            <router-link class="link" to="/news">{{ $t('news') }}</router-link>
          </li>

          <li>
            <router-link class="link" to="/contact">{{ $t('contactUs') }}</router-link>
          </li>
        </ul>

        <div class="additional-wrapper">
          <!--          <a class="search" @click="toggleAdvancedSearch">-->
          <!--            <img alt="Search" src="@/assets/icons/search.svg">-->
          <!--          </a>-->

          <div class="language-select">
            <div class="selected notranslate" @click="toggleLanguagesDropdown">
              <img alt="" src="@/assets/icons/language.svg">
              {{ titleSelectedLang }}
              <img alt="" src="@/assets/icons/expand_more.svg">
            </div>


            <div v-if="dropdownIsOpen" class="options notranslate">
              <a v-if="selectedLanguage !== 'en'"
                 class="option"
                 @click="changeLanguage('en')"
              >
                <img alt="" src="@/assets/icons/language.svg">
                {{ $t('eng') }}
              </a>

              <a v-if="selectedLanguage !== 'prs'"
                 class="option"
                 @click="changeLanguage('prs')"
              >
                <img alt="" src="@/assets/icons/language.svg">
                {{ $t('dari') }}
              </a>

              <a v-if="selectedLanguage !== 'ar'"
                 class="option"
                 @click="changeLanguage('ar')"
              >
                <img alt="" src="@/assets/icons/language.svg">
                {{ $t('arabic') }}
              </a>

              <a v-if="selectedLanguage !== 'ps'"
                 class="option"
                 @click="changeLanguage('ps')"
              >
                <img alt="" src="@/assets/icons/language.svg">
                {{ $t('pashto') }}
              </a>
              <a v-for="language in languages"
                 class="option"
                 @click="selectOptionByValue(language.value)"
              >
                <img alt="" src="@/assets/icons/language.svg">
                {{ language.text }}
              </a>
            </div>
          </div>

          <a class="irc" href="https://www.rescue.org/uk" target="_blank">
            <img :alt="$t('irc')" class="logo" src="@/assets/images/irc-logo.png">
          </a>

          <div class="person-logo" @click="toggleAccessibilityTools">
            <img :alt="$t('person')" class="logo" src="@/assets/images/person.png">
          </div>

          <button v-if="!isBlockVisible" class="burger" @click="showBlock">
            <img :alt="$t('burger')" src="@/assets/icons/burger.svg">
          </button>

          <button v-else class="burger" @click="hideBlock">
            <img alt="" src="@/assets/icons/close.svg">
          </button>

        </div>
      </div>
    </div>


    <!--    <div v-if="advancedSearchIsOpen" class="advanced-search container">-->
    <!--      <div class="search-input">-->
    <!--        <img alt="Search" src="@/assets/icons/search.svg">-->
    <!--        <input placeholder="Search">-->
    <!--        <a class="search" @click="toggleAdvancedSearch">-->
    <!--          <img alt="Close" src="@/assets/icons/close.svg">-->
    <!--        </a>-->
    <!--      </div>-->

    <!--      <h4 class="text-bold">Search-results:</h4>-->

    <!--      <div class="search-results">-->
    <!--        <div class="result">-->
    <!--          <h2>Information about something important to know</h2>-->
    <!--          <h2>Article</h2>-->
    <!--        </div>-->

    <!--        <div class="result">-->
    <!--          <h2>Information about something important to know</h2>-->
    <!--          <h2>Article</h2>-->
    <!--        </div>-->

    <!--        <div class="result">-->
    <!--          <h2>Information about something important to know</h2>-->
    <!--          <h2>Article</h2>-->
    <!--        </div>-->

    <!--        <div class="result">-->
    <!--          <h2>Information about something important to know</h2>-->
    <!--          <h2>Article</h2>-->
    <!--        </div>-->

    <!--        <div class="result">-->
    <!--          <h2>Information about something important to know</h2>-->
    <!--          <h2>Article</h2>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <AccessibilityTools :show-accessibility-tools="showAccessibilityTools"
                        @closeAccessibilityTools="toggleAccessibilityTools()"/>
    <header-mobile-menu :languages="languages"
                        :showBlock="isBlockVisible"
                        @closeBlock="hideBlock"
                        @lang-en="changeLanguage('en')"
                        @lang-prs="changeLanguage('prs')"
                        @lang-ar="changeLanguage('ar')"
                        @lang-ps="changeLanguage('ps')"
                        @language-clicked="selectOptionByValue"
                        :titleSelectedLangProp="titleSelectedLang"
    />

  </header>
</template>

<script>
import Slider from "@/components/Slider.vue";
import HeaderMobileMenu from "@/components/HeaderMobileMenu.vue";
import AccessibilityTools from "@/components/AccessibilityTools.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import GoogleTranslate from "@/components/GoogleTranslate.vue";
import languageService from "@/services/language";
import {EventBus} from "@/services/eventBus";
import Cookies from "js-cookie";

export default {
  name: 'Header',
  components: {GoogleTranslate, BreadCrumbs, AccessibilityTools, Slider, HeaderMobileMenu},

  data() {
    return {
      selectedLanguage: 'en',
      dropdownIsOpen: false,
      advancedSearchIsOpen: false,
      showAccessibilityTools: false,
      isBlockVisible: false,
      languages: [],
    }
  },
  computed: {
    titleSelectedLang() {
      let code = this.selectedLanguage;
      if (code === 'en') {
        return 'Eng';
      } else if (code === 'prs') {
        return 'Dari';
      } else if (code === 'ps') {
        return 'Pashto';
      } else if (code === 'ar') {
        return 'Arabic';
      } else {
        //find in languages array
        const language = this.languages.find(x => x.value === code);
        if (language) {
          return language.text;
        }
      }
    }
  },
  methods: {
    extractOptions() {
      this.$nextTick(() => {
        const selectElement = document.querySelector('.goog-te-combo');
        const options = selectElement.querySelectorAll('option');

        this.languages = Array.from(options)
            .filter(option => !['prs', 'en', 'ps', 'ar'].includes(option.value))
            .map(option => ({
              value: option.value,
              text: option.textContent
            }));

        if (!Cookies.get('googtrans')) {
          this.languages.shift();
        }
      })
    },


    observeDOM() {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.addedNodes.length > 0) {
            this.extractOptions();
          }
        });
      });

      observer.observe(document.body, {childList: true, subtree: true});
    },
    showBlock() {
      this.isBlockVisible = true;
      this.showAccessibilityTools = false;
    },

    hideBlock() {
      this.isBlockVisible = false;
    },

    changeLanguage(language) {
      this.selectedLanguage = language;
      this.dropdownIsOpen = false;
      languageService.changeLang(language, this.$i18n);
      EventBus.$emit('languageChangedHeader', this.selectedLanguage);
      Cookies.remove('googtrans')
      window.location.reload();
    },

    toggleLanguagesDropdown() {
      this.dropdownIsOpen = !this.dropdownIsOpen;
      this.$nextTick(() => {
        this.extractOptions();
      });
    },

    toggleAdvancedSearch() {
      this.advancedSearchIsOpen = !this.advancedSearchIsOpen
    },

    toggleAccessibilityTools() {
      this.hideBlock();
      this.showAccessibilityTools = !this.showAccessibilityTools;
    },

    scrollToForm() {
      this.hideBlock();
      if (this.$route.name !== 'home') {
        this.$router.push({path: '/'});
        setTimeout(() => {
          document.getElementById('form')?.scrollIntoView({behavior: 'smooth'})
        }, 100);
      }
      document.getElementById('form')?.scrollIntoView({behavior: 'smooth'})
    },

    selectOptionByValue(value) {
      console.log('Выбран язык с значением:', value);
      console.log(value);
      let selector = '.goog-te-combo';
      const selectElement = document.querySelector(selector);
      if (selectElement) {
        selectElement.value = value;
        // Для вызова события 'change', если это необходимо
        selectElement.dispatchEvent(new Event('change'));
        this.selectedLanguage = value;
        this.dropdownIsOpen = false;
        languageService.changeLang(value, this.$i18n);
        EventBus.$emit('languageChangedHeader', this.selectedLanguage);
      }
    }
  },


  mounted() {
    this.observeDOM();
    this.selectedLanguage = this.$i18n.locale;
    EventBus.$on('languageChangedHome', (language) => {
      this.selectedLanguage = language;
    });
  },

  created() {
    window.addEventListener('keydown', (event) => {
      if (event.keyCode === 85 && event.ctrlKey) {
        event.preventDefault();
        this.toggleAccessibilityTools();
      }
    });
  },

  watch: {
    $route() {
      this.isBlockVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 94px;
  background-color: $main-color;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 1224px) {
    padding: 0 16px;
  }
}

.nav {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.founded-by {
  width: 145px;
  height: 37px;
  @media (max-width: 1224px) {
    width: 97px;
    height: 24px;
  }
}

.rep-program {
  width: 166px;
  height: 37px;
  @media (max-width: 1224px) {
    width: 112px;
    height: 24px;
  }
}

.nav-list {
  display: flex;
  width: 525px;
  gap: 24px;
  list-style: none;

  li {
    a {
      text-decoration: none;
    }
  }

  @media (max-width: 1224px) {
    display: none;
  }

}

.additional-wrapper {
  width: 278px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1224px) {
    width: 80px;
  }
}

.search {
  transition: opacity .2s ease-in-out;

  @media (max-width: 1224px) {
    display: none;
  }

  &:hover {
    opacity: .8;
    cursor: pointer;
  }
}

.language-select {
  color: #ffffff;
  display: flex;
  width: 100px;
  align-items: center;
  font-size: var(--main-font-size);
  font-weight: 600;
  gap: 4px;
  position: relative;

  @media (max-width: 1224px) {
    display: none;
  }


  .selected {
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: opacity .2s ease-in-out;

    &:hover {
      opacity: .8;
      cursor: pointer;
    }
  }

  .options {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $main-color;
    //width: 100px;
    bottom: -625px;
    overflow: auto;
    max-height: 600px;
    left: -7px;
    z-index: 10;
  }

  .option {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border-bottom: 1px solid #ffffff;
    transition: opacity .2s ease-in-out;

    &:hover {
      cursor: pointer;
      opacity: .8;
    }

    &:last-child {
      border: none;
    }
  }
}

.person-logo {
  width: 40px;
  height: 40px;

  &:hover {
    cursor: pointer;
  }
}

.irc {
  width: 33px;
  height: 44px;
  @media (max-width: 1224px) {
    display: none;
  }
}

.advanced-search {
  position: absolute;
  height: 470px;
  padding: 16px 32px;
  background-color: $main-color;
  color: #ffffff;
  top: 94px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.search-input {
  height: 42px;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;

  input {
    height: 100%;
    background: none;
    border: none;
    outline: none;
    width: 95%;
    color: white;
    font-size: var(--main-font-size);
  }
}

.search-results {
  display: flex;
  flex-direction: column;
  height: 330px;
  overflow-y: auto;

  .result {
    border-bottom: 1px solid #ffffff24;
    padding: 10px 0;
  }
}

.burger {
  display: none;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  @media (max-width: 1224px) {
    display: block;
  }
}


</style>
